import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import BankService from '@/services/BankService';

@Mixin
export default class BankMixin extends Vue {
  public banks: Array<any> = [];

  public bankService = new BankService();

  async created() {
    const { data } = await this.bankService.getAllBanks();
    this.banks = [...data];
  }
}
